import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware/helpers";

export interface Breadcrumb {
  name: string;
  path: string;
}

export function getCategoryBreadcrumbs(
  category: Schemas["Category"],
  options: { startIndex?: number } = {},
): Breadcrumb[] {
  const seoUrl = category.seoUrls?.[0]?.seoPathInfo; // e.g.  "Produkte/Baumaschinen/Abbruchhaemmer/"

  if (!seoUrl) return [];

  // Split the path and filter out any empty strings
  const segments = seoUrl.split("/").filter((segment) => segment);

  const breadcrumbs: Breadcrumb[] = [];

  let currentPath = "/";

  const tranlatetedSegments = getTranslatedProperty(category, "breadcrumb");

  // Iterate over all segments and generate breadcrumbs
  segments.forEach((segment, index) => {
    currentPath += `${segment}/`;

    // Add breadcrumb if the current index is greater than or equal to the startIndex (default to 0)
    if (index >= (options.startIndex || 0)) {
      breadcrumbs.push({
        name: tranlatetedSegments[index + 1] as string,
        path: currentPath,
      });
    }
  });

  return breadcrumbs;
}
